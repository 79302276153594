<template>
  <gf-card>
    <el-form ref="si" :model="si" :rules="siRules">
      <gf-content v-loading="loading" overflow>
        <template #title>
          <span v-if="mode === 'create'" class="font-weight-bold">New Invoice</span>
          <span v-else>Edit Invoice #{{ si.si_number }}</span>
        </template>
        <template #toolbar>
          <span class="font-weight-bold text-muted">#{{ si.si_number }}</span>
        </template>
        <template #header>
          <el-row>
            <el-col :lg="9" :md="12" :sm="24">
              <el-form-item label="Bill To" label-width="100px" prop="bill_to_id">
                <span>{{ si.bill_to.label }}</span>
              </el-form-item>
              <el-form-item label="Ship To" label-width="100px" prop="ship_to_id">
                <span>{{ si.ship_to.label }}</span>
              </el-form-item>
            </el-col>
            <el-col :lg="9" :md="12" :sm="24">
              <el-form-item label="Terms" label-width="120px" prop="payment_term_id">
                <el-select v-model="si.payment_term_id" placeholder="Select" size="small" style="width: 100%;"
                  @change="changePaymentTerm">
                  <el-option v-for="pt in payment_terms" :key="pt.id" :label="pt.name" :value="pt.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Issued Date" label-width="120px">
                <el-date-picker v-model="si.issued_date" format="dd MMM yyyy" size="small" style="width: 100%;"
                  type="date" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Payment Due" label-width="120px">
                <el-date-picker v-model="si.payment_due" format="dd MMM yyyy" size="small" style="width: 100%;"
                  type="date" value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <div class="table">
          <gf-table :data="si.items" :scrollable="false">
            <el-table-column label="Item Name" min-width="200" prop="name">
              <template slot-scope="slot">
                <div class="gf-item">
                  <div class="gf-item__name">
                    <span v-if="slot.row.product.name === slot.row.variant.name && slot.row.variant.is_pack === 0">{{
                      slot.row.variant.name }}</span>
                    <span v-else-if="slot.row.variant.is_pack === 0">{{ slot.row.product.name }} - {{
                      slot.row.variant.name }}</span>
                    <span v-else>
                      <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{
                        slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
                      <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{
                        slot.row.variant.name }}</span>
                      <span> (Pack of {{ slot.row.variant.pack_size }})</span>
                    </span>
                  </div>
                  <div class="gf-item__sub">
                    <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Qty Invoice" min-width="120">
              <template slot-scope="slot">
                <el-form-item :prop="'items.' + slot.$index + '.quantity'"
                  :rules="{ required: true, message: ' ', trigger: 'blur' }" label-width="0">
                  <el-input v-model="slot.row.quantity" size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Not Invoice" min-width="120">
              <template slot-scope="slot">
                <el-input
                  :value="parseFloat(slot.row.total_quantity) - parseFloat(slot.row.quantity) - parseFloat(slot.row.order_item.invoiced)"
                  readonly size="mini" style="width: 100%"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Total Qty" min-width="120">
              <template slot-scope="slot">
                <el-form-item label-width="0">
                  <el-input v-model="slot.row.total_quantity" readonly size="mini" style="width: 100%"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Total" min-width="120">
              <template slot-scope="slot">
                <el-input
                  :value="$NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount / 100)))"
                  readonly size="mini" style="width: 100%"></el-input>
              </template>
            </el-table-column>
          </gf-table>
        </div>
        <div class="footer mt-4">
          <div class="note">
            <el-input v-model="si.message" :rows="2" placeholder="Message to Customer" type="textarea"></el-input>
          </div>
          <div class="gf-summary">
            <ul class="summary">
              <li class="summary__item">
                <span class="text">Total Units</span>
                <span class="value">{{ $NumberFormat(totalUnits) }}</span>
              </li>
              <li v-if="si.is_inclusive !== 2" class="summary__item">
                <span class="text">Subtotal</span>
                <span class="value">{{ $NumberFormat(subtotal) }}</span>
              </li>
              <div v-if="si.is_inclusive !== 2">
                <li v-for="(vat, index) in vats" :key="index" class="summary__item">
                  <span class="text">{{ (si.is_inclusive) ? 'Including' : 'Plus' }} VAT ({{ vat.rate }}%)</span>
                  <span class="value">{{ $NumberFormat(vat.value) }}</span>
                </li>
              </div>
              <li class="summary__item">
                <span class="text">Total</span>
                <span class="value">{{ $NumberFormat(total) }}</span>
              </li>
            </ul>
          </div>
        </div>
        <template #footer>
          <div class="text-right">
            <gf-button class="mr-3" size="sm" type="primary" @click="submit">Submit</gf-button>
            <gf-button size="sm" type="secondary" @click="cancel">Cancel</gf-button>
          </div>
        </template>
      </gf-content>
    </el-form>
  </gf-card>
</template>

<style lang="scss" scoped>
.el-form {
  height: 100%;
}

.content {
  .el-form-item {
    width: auto;
    margin: 0;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import SalesOrderService from '@/services/v1/SalesOrder'
import SalesInvoiceService from '@/services/v1/SalesInvoice'
import PaymentTermService from '@/services/v1/PaymentTerm'

export default {
  data() {
    return {
      salesOrderId: null,
      salesInvoiceId: null,
      loading: false,

      so_number: null,
      si: {
        si_number: 'SI000000',
        customer_id: null,
        bill_to_id: null,
        bill_to: {
          label: null
        },
        ship_to_id: null,
        ship_to: {
          label: null
        },
        ship_from_id: null,
        ship_from: {
          name: null
        },
        payment_term_id: null,
        issued_date: this.$DateISOFormat(new Date().toISOString()),
        payment_due: this.$DateISOFormat(new Date().toISOString()),
        message: null,
        is_inclusive: 0,
        items: [],
        vats: [],
        total: null,
        subtotal: null
      },

      // rules
      siRules: {
        si_number: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        payment_term_id: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      },

      // variables
      payment_terms: [],

      // helpers
      mode: 'create',

      // Rendering variables
      isSiEdit: false,

      // Services
      soService: null,
      ptService: null
    }
  },
  computed: {
    totalUnits() {
      let total = 0

      for (let i = 0; i < this.si.items.length; i++) {
        const e = this.si.items[i]
        total += parseFloat(e.quantity)
      }

      return total
    },
    subtotal() {
      let total = 0

      if (this.si.is_inclusive === 0) {
        for (let i = 0; i < this.si.items.length; i++) {
          const e = this.si.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          total += sub - discount
        }
      } else {
        for (let i = 0; i < this.si.items.length; i++) {
          const e = this.si.items[i]
          const sub = parseFloat(e.quantity) * parseFloat(e.cost)
          const discount = sub * parseFloat(e.discount / 100)
          const discounted = sub - discount
          const tax = parseFloat(e.tax) / 100
          total += discounted - (discounted * tax)
        }
      }

      return total
    },
    vats() {
      const vt = []

      if (this.si.items.length > 0) {
        for (let i = 0; i < this.si.items.length; i++) {
          const e = this.si.items[i]
          const found = vt.find(vat => vat.rate === e.tax)

          if (found) {
            found.value += (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
          } else {
            vt.push({
              rate: e.tax,
              value: (e.tax / 100) * (parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100)))
            })
          }
        }
      }

      return vt
    },
    total() {
      let total = 0

      if (this.si.items.length > 0) {
        for (let i = 0; i < this.si.items.length; i++) {
          const e = this.si.items[i]
          total += parseFloat(e.quantity) * parseFloat(e.cost) - (parseFloat(e.quantity) * parseFloat(e.cost) * parseFloat(e.discount / 100))
        }
      }

      if (!this.si.is_inclusive) {
        const vats = this.vats

        for (let i = 0; i < vats.length; i++) {
          const vat = vats[i]
          total += parseFloat(vat.value)
        }
      }

      return total
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'invoice-list' })
    },
    submit() {
      this.$refs.si.validate()
        .then(async () => {
          try {
            this.loading = true

            const si = this.$_.cloneDeep(this.si)
            this.$Sanitize(si)

            for (let i = 0; i < si.items.length; i++) {
              const item = si.items[i]
              delete item.variant
              delete item.product
              delete item.order_item
              delete item.created_at
              delete item.updated_at
            }

            delete si.bill_to
            delete si.ship_to
            delete si.ship_from
            delete si.created_at
            delete si.updated_at
            delete si.payment_term

            si.units = this.totalUnits
            si.subtotal = this.subtotal
            si.vats = JSON.stringify(this.vats)
            si.total = this.total

            if (this.mode === 'create') {
              const siService = new SalesInvoiceService(this.salesOrderId)
              const result = await siService.create(si)
              this.$emit('update:update', true)
              this.$message.success(`${this.si.si_number} successfully created`)
              this.$router.push({ name: 'invoice-view', params: { id: this.salesOrderId, iid: result.data.id } })
            } else {
              const siService = new SalesInvoiceService(this.salesOrderId, this.salesInvoiceId)
              await siService.update(si)
              this.$emit('update:update', true)
              this.$message.success(`${this.si.si_number} successfully updated`)
              this.$router.push({ name: 'invoice-view', params: { id: this.salesOrderId, iid: this.salesInvoiceId } })
            }
          } catch (error) {
            this.$Error(error)
          } finally {
            this.loading = false
          }
        })
        .catch(() => { })
    },
    changePaymentTerm(index) {
      const pt = this.payment_terms.find(p => p.id === index)
      const d1 = new Date()

      if (pt.from === 1) {
        d1.setMonth(d1.getMonth() + 1, 0)
      }

      d1.setDate(d1.getDate() + pt.due_days)
      this.si.payment_due = this.$DateISOFormat(d1.toISOString())
    },

    // Service fetch
    async setSINumber() {
      try {
        const siService = new SalesInvoiceService(this.salesOrderId)
        const result = await siService.getLastSI()
        let siNumber = result.data.si_number

        if (!siNumber) siNumber = 'SI000000'

        const number = parseInt(siNumber.substring(2)) + 1
        this.si.si_number = 'SI' + this.$Numeral(number).format('000000')
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesOrder() {
      try {
        const soService = new SalesOrderService(this.salesOrderId)
        const result = await soService.get()
        this.so_number = result.data.so_number
        this.si.customer_id = result.data.customer_id
        this.si.bill_to_id = result.data.bill_to_id
        this.si.bill_to = result.data.bill_to
        this.si.ship_to_id = result.data.ship_to_id
        this.si.ship_to = result.data.ship_to
        this.si.ship_from_id = result.data.ship_from_id
        this.si.ship_from = result.data.ship_from
        this.si.is_inclusive = result.data.is_inclusive

        for (let i = 0; i < result.data.items.length; i++) {
          const item = result.data.items[i]
          this.si.items.push({
            sales_order_item_id: item.id,
            variant_id: item.variant_id,
            product: item.product,
            variant: item.variant,
            quantity: item.quantity - item.invoiced,
            cost: item.cost,
            order_item: { invoiced: item.invoiced || 0 },
            total_quantity: item.quantity,
            discount: item.discount,
            tax: item.tax
          })
        }
      } catch (error) {
        this.$Error(error)
      }
    },
    async getPaymentTerms() {
      try {
        const ptService = new PaymentTermService()
        const result = await ptService.list()
        this.payment_terms = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesOrderNo() {
      try {
        const soService = new SalesOrderService(this.salesOrderId)
        const result = await soService.getSOandStatus()
        this.so_number = result.data.so_number
      } catch (error) {
        this.$Error(error)
      }
    },
    async getSalesInvoice() {
      try {
        this.loading = true

        const siService = new SalesInvoiceService(this.$route.params.id, this.$route.params.iid)
        const result = await siService.get()
        this.si = result.data
        this.si.issued_date = this.$DateISOFormat(this.si.issued_date)
        this.si.payment_due = this.$DateISOFormat(this.si.payment_due)

        for (let i = 0; i < this.si.items.length; i++) {
          const item = this.si.items[i]
          item.order_item.invoiced = parseFloat(item.order_item.invoiced) - parseFloat(item.quantity)
        }
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    this.loading = true

    this.mode = this.$route.meta.mode
    this.salesOrderId = this.$route.params.id
    this.salesInvoiceId = this.$route.params.iid

    await this.getPaymentTerms()

    if (this.mode === 'create') {
      await this.getSalesOrder()
      await this.setSINumber(this.salesOrderId)
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so_number },
        { title: 'Invoices' },
        { title: 'New' }
      ])

      this.loading = false
    } else {
      await this.getSalesOrderNo()
      await this.getSalesInvoice()
      this.$store.dispatch(this.$SET_BREADCRUMB, [
        { title: 'Sales Order', route: '' },
        { title: 'Order' },
        { title: this.so_number },
        { title: 'Invoices' },
        { title: this.si.si_number },
        { title: 'Edit' }
      ])
    }
  }
}
</script>
